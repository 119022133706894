import React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserForm = () => {
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        const usuario = event.target.usuario.value;
        const nip = event.target.nip.value;
        const data = { usuario, nip };

        axios.post('https://mariscosdelvalle.com.mx/apis/login.php', data, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        })
            .then((response) => {
                console.log(response.data);
                if (response.data === 'Usuario no encontrado') {
                    toast.warn('El usuario no existe', {
                        position: "top-center",
                        autoClose: 2000,
                        style: {
                            backgroundColor: '#1a202c',
                            color: '#e53e3e',
                            border: '1px solid #e53e3e',
                            borderRadius: '8px',
                        },
                    });
                } else {
                    if (response.data[0].Nip === nip && response.data[0].Usuario === usuario) {
                        toast.success('Contraseña correcta', {
                            position: "top-center",
                            autoClose: 2000,
                            style: {
                                backgroundColor: '#1a202c',
                                color: '#48bb78',
                                border: '1px solid #48bb78',
                                borderRadius: '8px',
                            },
                        });
                        sessionStorage.setItem('Nombre', response.data[0].nombres);
                        sessionStorage.setItem('rol', response.data[0].Rol);
                        if (response.data[0].Rol === 'Cocina' || response.data[0].Rol === 'Barra' || response.data[0].Rol === 'Bebidas') {
                            navigate('/Comanda');
                        } else {
                            navigate('/PuntoDeVenta');
                        }
                    } else {
                        toast.warn('Contraseña Incorrecta', {
                            position: "top-center",
                            autoClose: 2000,
                            style: {
                                backgroundColor: '#1a202c',
                                color: '#e53e3e',
                                border: '1px solid #e53e3e',
                                borderRadius: '8px',
                            },
                        });
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-900 px-4">
            <div className="w-full sm:max-w-md bg-gray-800 rounded-lg shadow-lg p-6 overflow-y-auto">
                <h1 className="text-2xl font-semibold text-center text-white mb-6">
                    Ingreso de Usuario
                </h1>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label htmlFor="usuario" className="block text-sm font-medium text-gray-300">
                            Usuario
                        </label>
                        <input
                            id="usuario"
                            name="usuario"
                            type="text"
                            required
                            className="mt-1 block w-full px-3 py-2 bg-gray-700 text-gray-300 border border-gray-600 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="nip" className="block text-sm font-medium text-gray-300">
                            NIP
                        </label>
                        <input
                            id="nip"
                            name="nip"
                            type="password"
                            required
                            className="mt-1 block w-full px-3 py-2 bg-gray-700 text-gray-300 border border-gray-600 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 rounded-md transition-colors"
                    >
                        Ingresar
                    </button>
                </form>
            </div>
            <ToastContainer />
        </div>
    );
};

export default UserForm;
