import React, { useState, useEffect } from 'react';
import {
    Box, Fab, Modal, Typography, Divider, Button, IconButton,
    Stack, TextField, Snackbar, Alert
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import axios from 'axios';
import Pusher from 'pusher-js';
import { v4 as uuidv4 } from 'uuid';
import CardMesa from './Card.jsx';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '90%', md: 'auto' },
    maxWidth: 800, // Ajuste del ancho

    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 2,
    maxHeight: '80vh',
    overflowY: 'auto',
};


const Pedidos = () => {
    /* const rol = sessionStorage.getItem('rol'); */

    const [categorias, setCategorias] = useState([]);
    const [productos, setProductos] = useState([]);
    const [mesa, setMesa] = useState('');
    const [orden, setOrden] = useState({});
    const [descripciones, setDescripciones] = useState({});
    const [value, setValue] = useState('0');
    const [open, setOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [contenidoSnackBar, setContenidoSnackBar] = useState('');
    const nombreUsuario = sessionStorage.getItem('Nombre');
    //const rol = sessionStorage.getItem('rol');
    const [mesasActivas, setMesasActivas] = useState({});
    const [statusEditar, setStatusEditar] = useState(false);



    const [datosEditar, setDatosEditar] = useState([]);
    const [textoBtn, setTextoBtn] = useState('Añadir Mesa');

    const handleVisibilityChange = () => {
        if (!document.hidden) {
            conexion();

        } else {
            desconexion();
        }
    };
    let pusher;
    let channel;

    const conexion = () => {
        cargarPedidos();
        // Configuración de Pusher
        pusher = new Pusher('a8414ec3c102e530dd3a', {
            cluster: 'us2',
            logToConsole: true,  // Puedes habilitar para depurar
        });

        // Manejo de errores de Pusher
        pusher.connection.bind('error', function (err) {
            console.error('Pusher Error:', err);
            // Aquí puedes agregar lógica adicional si es necesario, como reintentos
        });

        // Verificar la conexión
        pusher.connection.bind('connected', () => {
            console.log('Pusher connected!');
        });

        // Suscribirse al canal de 'pedidos'
        channel = pusher.subscribe('pedidos');

        // Manejador de eventos para 'cargarPedidos'
        channel.bind('cargarPedidos', function (response) {
            cargarPedidos();  // Actualizar los pedidos cuando llegue un evento
        });
    }

    const desconexion = () => {
        channel.unbind_all(); // Desvincula todos los eventos
        pusher.unsubscribe('pedidos'); // Desuscribe del canal
    }
    useEffect(() => {
        // Cargar el menú y los pedidos cuando el componente se monte
        cargarMenu();
        conexion();
        // Detectar cuando la app se vuelve visible o no
        document.addEventListener('visibilitychange', handleVisibilityChange);
        // Limpiar recursos cuando el componente se desmonte
        return () => {
            desconexion();
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };

    }, []);

    const cargarMenu = () => {
        axios.get('https://mariscosdelvalle.com.mx/apis/menu.php', {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        })
            .then((response) => {
                setCategorias(response.data.categorias);
                setProductos(response.data.productos);
                setValue(response.data.categorias[0].categoria);
            })
            .catch((error) => console.error(error));
    }

    const cargarPedidos = () => {
        /* if (relod) { */
        //CARGAR ORDENES LISTAS
        const toDay = new Date();
        const dia = toDay.getDate() < 10 ? `0${toDay.getDate()}` : `${toDay.getDate()}`;
        const mes = toDay.getMonth() + 1 < 10 ? `0${toDay.getMonth() + 1}` : `${toDay.getMonth() + 1}`;
        const fechaActual = `${toDay.getFullYear()}-${mes}-${dia}`;
        const data = {
            fecha: fechaActual
        };
        console.log(data);

        axios.post('https://mariscosdelvalle.com.mx/apis/cargarPedidosActivos.php', data, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        })
            .then((response) => {
                //console.log('RESPUESTA CARGAR PEDIDOS', response.data);
                //response.data.length == 0 ? null : setMesasActivas(response.data);

                setMesasActivas(response.data);
            })
            .catch((error) => console.error(error));
        /* } else {
            console.log('Pusher');
        } */

    }

    const handleChange = (event, newValue) => setValue(newValue);
    const handleOpen = () => {
        setStatusEditar(false);
        setOrden({});
        setMesa('');
        setDescripciones({});
        setTextoBtn('Añadir Mesa');
        setOpen(true)
    };
    const handleClose = () => setOpen(false);

    const handleSnackbarClose = () => setSnackbarOpen(false);

    const aumentarCantidad = (producto) => {
        if (!mesa) return;

        setOrden((prevOrden) => {
            const nuevaOrden = { ...prevOrden };
            if (!nuevaOrden[mesa]) nuevaOrden[mesa] = {};

            if (!nuevaOrden[mesa][producto.producto]) {
                nuevaOrden[mesa][producto.producto] = {
                    cantidad: 1,
                    descripcion: descripciones[producto.producto] || '',
                    precio: producto.precio,
                    categoria: producto.categoria,
                    preparacion: producto.preparacion
                };
            } else {
                nuevaOrden[mesa][producto.producto].cantidad += 1;
            }
            return nuevaOrden;
        });
    };

    const disminuirCantidad = (producto) => {
        setOrden((prevOrden) => {
            const nuevaOrden = { ...prevOrden };
            if (nuevaOrden[mesa] && nuevaOrden[mesa][producto.producto]) {
                nuevaOrden[mesa][producto.producto].cantidad -= 1;
                if (nuevaOrden[mesa][producto.producto].cantidad === 0) {
                    delete nuevaOrden[mesa][producto.producto];
                    if (Object.keys(nuevaOrden[mesa]).length === 0) {
                        delete nuevaOrden[mesa];
                    }
                }
            }
            return nuevaOrden;
        });
    };

    const handleDescripcionChange = (producto, descripcion) => {
        setDescripciones((prev) => ({ ...prev, [producto]: descripcion }));
        if (orden[mesa] && orden[mesa][producto]) {
            setOrden((prevOrden) => ({
                ...prevOrden,
                [mesa]: {
                    ...prevOrden[mesa],
                    [producto]: { ...prevOrden[mesa][producto], descripcion }
                }
            }));
        }
    };

    const claveCruda = (dec) => {
        return dec.toString(32).padStart(2, "0");
    }

    const crearClave = (longitud) => {
        const num = new Uint8Array((longitud || 40) / 2);
        window.crypto.getRandomValues(num);
        return Array.from(num, claveCruda).join('');
    }

    function generarUUIDv4Recortado(longitud) {
        // Generar el UUID v4
        const uuid = uuidv4();

        // Recortar el UUID a la longitud deseada
        return uuid.replace(/-/g, '').substring(0, longitud); // Eliminar guiones y truncar
    }

    const handleSubmit = () => {
        const data = [];

        if (!mesa) {
            setContenidoSnackBar('Por favor ingresa un nombre para la mesa.');
            setSnackbarOpen(true);
            return;
        } else if (Object.entries(orden).length === 0) {
            setContenidoSnackBar('Añade al menos 1 producto');
            setSnackbarOpen(true);
        } else {
            if (isSubmitting) return; // Prevenir múltiples envíos
            setIsSubmitting(true); // Desactiva el botón
            if (statusEditar === true) {
                const toDay = new Date();
                const dia = toDay.getDate() < 10 ? `0${toDay.getDate()}` : `${toDay.getDate()}`;
                const mes = toDay.getMonth() + 1 < 10 ? `0${toDay.getMonth() + 1}` : `${toDay.getMonth() + 1}`;
                const fechaActual = `${toDay.getFullYear()}-${mes}-${dia}`;
                const data = [];
                const ordenClave = crearClave(8).toUpperCase();
                const idMesa = generarUUIDv4Recortado(5);

                Object.entries(orden).forEach(([nombre, detalles]) => {


                    Object.entries(detalles).forEach(([producto, detallesProducto]) => {
                        data.push({
                            categoria: detallesProducto.categoria,
                            idProductoEliminar: parseInt(datosEditar[0].id),
                            precio: detallesProducto.precio,
                            descripcion: detallesProducto.descripcion,
                            cantidad: parseInt(detallesProducto.cantidad),
                            producto: producto,
                            mesa: mesa,
                            mesero: nombreUsuario,
                            estado: detallesProducto.estado !== undefined ? detallesProducto.estado : 'Pendiente',
                            hora: new Date().toLocaleTimeString(),
                            fecha: fechaActual,
                            noOrden: ordenClave,
                            id_mesa: idMesa,
                            preparacion: detallesProducto.preparacion
                        })
                    })
                });
                const pedidoJSON = JSON.stringify(data);
                axios.post('https://mariscosdelvalle.com.mx/apis/editarProducto.php', pedidoJSON, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then((response) => {
                        setMesa('');
                        setDescripciones({});
                        setOpen(false);
                        setOrden({});
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally(() => {
                        setIsSubmitting(false); // Reactiva el botón
                    });
            } else {
                const ordenClave = crearClave(8).toUpperCase();
                const idMesa = generarUUIDv4Recortado(5);
                const toDay = new Date();
                const dia = toDay.getDate() < 10 ? `0${toDay.getDate()}` : `${toDay.getDate()}`;
                const mes = toDay.getMonth() + 1 < 10 ? `0${toDay.getMonth() + 1}` : `${toDay.getMonth() + 1}`;
                const fechaActual = `${toDay.getFullYear()}-${mes}-${dia}`;
                Object.entries(orden).forEach(([nombre, detalles]) => {
                    Object.entries(detalles).forEach(([producto, detallesProducto]) => {
                        data.push({
                            producto: producto,
                            cantidad: detallesProducto.cantidad,
                            descripcion: detallesProducto.descripcion,
                            precio: detallesProducto.precio,
                            mesa: mesa,
                            mesero: nombreUsuario,
                            categoria: detallesProducto.categoria,
                            estado: 'Pendiente',
                            hora: new Date().toLocaleTimeString(),
                            fecha: fechaActual,
                            noOrden: ordenClave,
                            id_mesa: idMesa,
                            preparacion: detallesProducto.preparacion
                        })
                    })
                });
                const pedidoJSON = JSON.stringify(data);

                axios.post('https://mariscosdelvalle.com.mx/apis/pedidos.php', pedidoJSON, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
                    .then((response) => {
                        setMesa('');
                        setDescripciones({});
                        setOpen(false);
                        setOrden({});
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally(() => {
                        setIsSubmitting(false); // Reactiva el botón
                    });
            }


        }
    };

    const total = mesa in orden
        ? Object.values(orden[mesa]).reduce((acc, item) => acc + item.precio * item.cantidad, 0)
        : 0;

    const handleAction = (action, id) => {
        if (action === 'Eliminar') {
            const idEnviar = { id: id.toString() };

            // Confirmación del usuario antes de ejecutar el axios
            const confirmacion = window.confirm("¿Estás seguro de que deseas eliminar esta mesa?");

            if (confirmacion) {
                axios.post('https://mariscosdelvalle.com.mx/apis/eliminarMesa.php', idEnviar, {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                })
                    .then((response) => {
                        // console.log(response.data);

                    })
                    .catch((error) => console.error(error));
            }
        } else if (action === 'Editar') {

            setDatosEditar(id);
            setStatusEditar(true);
            const objDesc = {};
            const obj = {};
            obj[id[0].mesa] = {};
            for (let i = 0; i < id.length; i++) {
                obj[id[0].mesa][id[i].producto] = {
                    cantidad: parseInt(id[i].cantidad),
                    categoria: id[i].categoria,
                    descripcion: id[i].descripcion,
                    precio: id[i].precio,
                    idProducto: id[i].id,
                    preparacion: id[i].preparacion,
                    estado: id[i].estado
                }
                objDesc[id[i].producto] = id[i].descripcion;
            }
            setDescripciones(objDesc);
            setTextoBtn('Editar Mesa');
            setOrden(obj);
            setMesa(id[0].mesa);
            setOpen(true);
        } else if (action === 'entregarPlatillo') {
            const idEnviar = { id: id };
            axios.post('https://mariscosdelvalle.com.mx/apis/entregarPlatillo.php', idEnviar, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            })
                .then((response) => {

                    // console.log(response.data);

                })
                .catch((error) => console.error(error));
        }


    };



    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 2 }}>
            <Grid container spacing={2} justifyContent="center" alignItems="start" sx={{
                flexDirection: { xs: "column", sm: "row" }, // Columna en xs, fila en sm y mayores

            }}>
                {Object.entries(mesasActivas).map(([noOrden, value]) => (
                    <CardMesa value={value} noOrden={noOrden} funcion={handleAction} />
                ))}

                {/* Botón flotante para agregar */}
                <Fab sx={{
                    backgroundColor: 'rgba(0, 128, 0, 0.2)', alignSelf: 'center',
                    position: 'sticky',
                    bottom: 16,
                }} onClick={handleOpen}>
                    <FontAwesomeIcon icon={faPlus} size="2x" />
                </Fab>

            </Grid>
            <Modal open={open} onClose={handleClose}>
                <Box sx={modalStyle}>
                    <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        spacing={2}
                        sx={{ flexWrap: 'wrap', height: '100%' }} // Ajustar altura
                    >
                        <Box sx={{ flex: 1, width: '100%' }}> {/* Asegura que el Box ocupe el 100% del Stack */}
                            <Typography variant="h6" align="center">Agregar Pedido</Typography>
                            <TextField
                                fullWidth
                                label="Nombre de la Mesa"
                                value={mesa}
                                onChange={(e) => setMesa(e.target.value)}
                                sx={{ mb: 2 }}
                            />
                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChange} variant="scrollable" scrollButtons="auto">
                                        {categorias.map((cat, index) => (
                                            <Tab key={index} label={cat.categoria} value={cat.categoria} />
                                        ))}
                                    </TabList>
                                </Box>
                                {categorias.map((cat, index) => (
                                    <TabPanel key={index} value={cat.categoria}>
                                        <Grid container spacing={2}>
                                            {productos.map((prod, indexProd) => (
                                                cat.categoria === prod.categoria && (
                                                    <Grid item xs={12} sm={6} key={indexProd}>
                                                        <Box sx={{ mt: 2, p: 2, backgroundColor: '#f5f5f5', borderRadius: 2 }}>
                                                            <Typography variant="h6">{prod.producto}</Typography>
                                                            <TextField
                                                                fullWidth
                                                                label="Descripción"
                                                                value={descripciones[prod.producto] || ''}
                                                                onChange={(e) => handleDescripcionChange(prod.producto, e.target.value)}
                                                                sx={{ mb: 2 }}
                                                            />
                                                            <Stack direction="row" spacing={2} alignItems="center">
                                                                <IconButton onClick={() => disminuirCantidad(prod)} sx={{ color: 'red' }}>
                                                                    <RemoveCircleIcon fontSize="large" />
                                                                </IconButton>
                                                                <Typography variant="h5">
                                                                    {orden[mesa]?.[prod.producto]?.cantidad || 0}
                                                                </Typography>
                                                                <IconButton onClick={() => aumentarCantidad(prod)} sx={{ color: 'green' }}>
                                                                    <AddCircleIcon fontSize="large" />
                                                                </IconButton>
                                                            </Stack>
                                                        </Box>
                                                    </Grid>
                                                )
                                            ))}
                                        </Grid>
                                    </TabPanel>
                                ))}
                            </TabContext>
                        </Box>

                        {/* Ticket en tiempo real */}
                        <Box sx={{ width: 300, bgcolor: '#f0f0f0', borderRadius: 2, p: 2 }}>
                            <Typography variant="h6" align='center'>Ticket</Typography>
                            <Divider sx={{ my: 1 }} />
                            {mesa in orden && Object.entries(orden[mesa]).map(([key, item]) => (
                                <Box key={key} sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                    <Typography>{item.cantidad}x {key}</Typography>
                                    <Typography>${(item.cantidad * item.precio).toFixed(2)}</Typography>
                                </Box>
                            ))}
                            <Divider sx={{ my: 1 }} />
                            <Typography variant="h6" align="center">Total: ${total.toFixed(2)}</Typography>
                        </Box>
                    </Stack>
                    <Button onClick={handleSubmit} variant="contained" color="success" fullWidth sx={{ marginTop: 2 }} disabled={isSubmitting}>
                        {isSubmitting ? 'Procesando...' : 'Añadir Mesa'}
                    </Button>
                </Box>
            </Modal>

            {/* TOAST */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity="warning" sx={{ width: '100%' }}>
                    {contenidoSnackBar}
                </Alert>
            </Snackbar>
        </Box >
    );
};

export default Pedidos;
