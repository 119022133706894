import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import './CalendarInput.css'; // Archivo CSS separado


const Reportes = () => {
    const rol = sessionStorage.getItem('rol');
    const [reporte, setReporte] = useState([]);
    const [reporteEliminados, setReporteEliminados] = useState([]);
    const [reporteArticulos, setReporteArticulos] = useState([]);
    const [reporteMeseros, setReporteMeseros] = useState([]);
    const [sumaTotalReporte, setSumaTotalReporte] = useState(0);
    const [sumaTotalEliminados, setSumaTotalEliminados] = useState(0);


    useEffect(() => {
        const toDay = new Date();
        const dia = toDay.getDate() < 10 ? `0${toDay.getDate()}` : `${toDay.getDate()}`;
        const mes = toDay.getMonth() + 1 < 10 ? `0${toDay.getMonth() + 1}` : `${toDay.getMonth() + 1}`;
        const fechaActual = `${toDay.getFullYear()}-${mes}-${dia}`;
        cargarReportes(fechaActual);
    }, []);

    const cargarReportes = (fecha) => {
        const data = {
            fecha: fecha
        };
        console.log(data);

        axios.post('https://mariscosdelvalle.com.mx/apis/cargarReporte.php', data, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        })
            .then((response) => {
                console.log(response.data);

                const arrayEliminados = response.data.arrayEliminados;
                const arrayProductos = response.data.array;
                const arrayArticulos = [];
                const arrayMeseros = [];
                const reporteCompleto = [];
                //arreglo de articulos
                for (let i = 0; i < arrayProductos.length; i++) {
                    let producto = arrayArticulos.some(produc => produc.producto === arrayProductos[i].producto);
                    if (producto === false) {
                        arrayArticulos.push({
                            categoria: arrayProductos[i].categoria,
                            cantidad: parseInt(arrayProductos[i].cantidad),
                            precio: parseInt(arrayProductos[i].precio),
                            producto: arrayProductos[i].producto
                        });
                    } else if (producto === true) {
                        let index = arrayArticulos.findIndex(indexProd => indexProd.producto === arrayProductos[i].producto);
                        if (index !== '-1') {
                            arrayArticulos[index].cantidad = arrayArticulos[index].cantidad + parseInt(arrayProductos[i].cantidad);
                        }
                    }
                }
                //arreglo de meseros
                for (let a = 0; a < arrayProductos.length; a++) {
                    let mesero = arrayMeseros.some(meseroArray => meseroArray.nombre === arrayProductos[a].mesero);
                    if (mesero === false) {
                        arrayMeseros.push({
                            nombre: arrayProductos[a].mesero,
                            total: arrayProductos[a].cantidad * arrayProductos[a].precio
                        });
                    } else if (mesero === true) {
                        let index = arrayMeseros.findIndex(indexProd => indexProd.nombre === arrayProductos[a].mesero);
                        if (index !== '-1') {
                            arrayMeseros[index].total = arrayMeseros[index].total + (parseInt(arrayProductos[a].precio) * parseInt(arrayProductos[a].cantidad));
                        }
                    }
                }
                //REPORTE COMPLETO
                for (let b = 0; b < arrayProductos.length; b++) {
                    let servicio = reporteCompleto.some(reporteFinal => reporteFinal.noOrden === arrayProductos[b].noOrden);
                    if (servicio === false) {
                        reporteCompleto.push({
                            nombreMesa: arrayProductos[b].mesa,
                            noOrden: arrayProductos[b].noOrden,
                            hora: arrayProductos[b].hora,
                            mesero: arrayProductos[b].mesero,
                            total: arrayProductos[b].precio * arrayProductos[b].cantidad,
                            pagado: arrayProductos[b].estado,
                            cancelado: arrayProductos[b].estado === 'Pendiente' ? 0 : 1,
                            metodo: arrayProductos[b].metodo
                        });
                    } else if (servicio === true) {
                        let index = reporteCompleto.findIndex(indexProd => indexProd.noOrden === arrayProductos[b].noOrden);
                        if (index !== '-1') {
                            reporteCompleto[index].total = reporteCompleto[index].total + (parseInt(arrayProductos[b].precio) * parseInt(arrayProductos[b].cantidad));
                        }
                    }
                }
                let cuentaTotal = 0;
                for (let cuenta = 0; cuenta < reporteCompleto.length; cuenta++) {
                    cuentaTotal = cuentaTotal + reporteCompleto[cuenta].total;
                }

                /* Reporte Eliminados */
                const reporteCompletoEliminados = [];
                for (let b = 0; b < arrayEliminados.length; b++) {
                    let servicio = reporteCompletoEliminados.some(reporteFinal => reporteFinal.noOrden === arrayEliminados[b].noOrden);
                    if (servicio === false) {
                        reporteCompletoEliminados.push({
                            nombreMesa: arrayEliminados[b].mesa,
                            noOrden: arrayEliminados[b].noOrden,
                            hora: arrayEliminados[b].hora,
                            mesero: arrayEliminados[b].mesero,
                            total: arrayEliminados[b].precio * arrayEliminados[b].cantidad,
                            pagado: arrayEliminados[b].estado,
                            cancelado: arrayEliminados[b].estado === 'Pendiente' ? 0 : 1,
                        });
                    } else if (servicio === true) {
                        let index = reporteCompletoEliminados.findIndex(indexProd => indexProd.noOrden === arrayEliminados[b].noOrden);
                        if (index !== '-1') {
                            reporteCompletoEliminados[index].total = reporteCompletoEliminados[index].total + (parseInt(arrayEliminados[b].precio) * parseInt(arrayEliminados[b].cantidad));
                        }
                    }
                }
                let cuentaTotalEliminados = 0;
                for (let cuentaEliminados = 0; cuentaEliminados < reporteCompletoEliminados.length; cuentaEliminados++) {
                    cuentaTotalEliminados = cuentaTotalEliminados + reporteCompletoEliminados[cuentaEliminados].total;
                }

                setSumaTotalReporte(cuentaTotal);
                setSumaTotalEliminados(cuentaTotalEliminados);
                setReporteEliminados(reporteCompletoEliminados);
                setReporte(reporteCompleto);
                setReporteMeseros(arrayMeseros);
                setReporteArticulos(arrayArticulos);
            })
            .catch((error) => console.error(error));
    }


    const [selectedDate, setSelectedDate] = useState('');

    const handleDateChange = (event) => {
        const date = event.target.value;
        setSelectedDate(date);
        cargarReportes(date);
        console.log('Fecha seleccionada:', date);
    };

    return (
        <>
            <div className="calendar-container">
                <input
                    type="date"
                    id="date"
                    className="calendar-input"
                    value={selectedDate}
                    onChange={handleDateChange} // Evento para capturar la fecha
                />
            </div>
            <TableContainer component={Paper} size="small" sx={{ overflowX: 'hidden', paddingTop: 5 }}>
                <Table sx={{ width: '100%' }} aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{ background: '#93E1D8' }}>
                            <TableCell
                                align="right"
                                sx={{
                                    display: { xs: 'none', sm: 'table-cell' }  // Oculta en pantallas pequeñas
                                }}
                            >PEDIDOS</TableCell>
                            <TableCell
                            ><b>Servicios</b></TableCell>
                            <TableCell
                                align="right"
                                sx={{
                                    display: { xs: 'none', sm: 'table-cell' }  // Oculta en pantallas pequeñas
                                }}
                            ><b>Cancelado</b></TableCell>
                            <TableCell
                                align="right"
                            ><b>Pagado</b></TableCell>
                            <TableCell
                                align="right"
                                sx={{
                                    display: { xs: 'none', sm: 'table-cell' }  // Oculta en pantallas pequeñas
                                }}
                            ><b>Mesero</b></TableCell>
                            <TableCell
                                align="right"
                            ><b>Total (${sumaTotalReporte})</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reporte ? reporte.map((array, index) => (
                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row"
                                    align="right"
                                    sx={{
                                        display: { xs: 'none', sm: 'table-cell' }  // Oculta en pantallas pequeñas
                                    }}>{array.hora}</TableCell>
                                <TableCell
                                >{array.nombreMesa}</TableCell>
                                <TableCell
                                    align="right"
                                    sx={{
                                        display: { xs: 'none', sm: 'table-cell' }  // Oculta en pantallas pequeñas
                                    }}
                                >{array.cancelado}</TableCell>
                                <TableCell
                                    align="right"
                                    sx={{
                                        display: { sm: 'table-cell', },
                                        fontSize: { xs: '0.75rem', sm: '1rem' }, // Tamaño de letra dinámico // Oculta en pantallas pequeñas
                                    }}
                                >
                                    {array.pagado === 'Pendiente' ? <ClearIcon /> : array.metodo === 'otro' ? 'Tarjeta/Transferencia' : array.metodo === '' ? 'No especificado' : array.metodo === 'efectivo' ? 'Efectivo' : null}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    sx={{
                                        display: { xs: 'none', sm: 'table-cell' }  // Oculta en pantallas pequeñas
                                    }}
                                >{array.mesero}</TableCell>
                                <TableCell align="right">${array.total}</TableCell>
                            </TableRow>
                        )) : null}
                    </TableBody>
                </Table>
            </TableContainer >

            {/* USUARIOS REPORTE */}
            < TableContainer component={Paper} sx={{ marginTop: 2, overflowX: 'hidden' }
            } size="small" >
                <Table sx={{ width: '100%' }} aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{ background: '#BC4749' }}>
                            <TableCell><b>USUARIOS</b></TableCell>
                            <TableCell><b>TOTAL</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reporteMeseros ? reporteMeseros.map((arrayMeseros, indexMeseros) => (
                            <TableRow key={indexMeseros} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">{arrayMeseros.nombre}</TableCell>
                                <TableCell component="th" scope="row" align="right">${arrayMeseros.total}</TableCell>
                            </TableRow>
                        )) : null}
                    </TableBody>
                </Table>
            </TableContainer >

            {/* Reporte artículos */}
            < TableContainer component={Paper} sx={{ marginTop: 2, overflowX: 'hidden' }} size="small" >
                <Table sx={{ width: '100%' }} aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{ background: '#CBB3BF' }}>
                            <TableCell><b>({reporteArticulos.length === 0 ? null : reporteArticulos.length + 1}) Artículos</b></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reporteArticulos ? reporteArticulos.map((arrayArticulos, indexArticulos) => (
                            <TableRow key={indexArticulos} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                    {arrayArticulos.cantidad} - {arrayArticulos.producto}
                                </TableCell>
                                <TableCell component="th" scope="row" align="right">
                                    ${arrayArticulos.cantidad * arrayArticulos.precio}
                                </TableCell>
                            </TableRow>
                        )) : null}
                    </TableBody>
                </Table>
            </TableContainer >
            {/* Reporte Eliminados*/}
            {rol === 'Full' ?
                <>
                    <h3>Pedidos Eliminados</h3>
                    <TableContainer component={Paper} size="small" sx={{ overflowX: 'hidden' }}>
                        <Table sx={{ width: '100%' }} aria-label="simple table">
                            <TableHead>
                                <TableRow sx={{ background: '#FF8652' }}>
                                    <TableCell
                                        align="right"
                                        sx={{
                                            display: { xs: 'none', sm: 'table-cell' }  // Oculta en pantallas pequeñas
                                        }}
                                    >PEDIDOS</TableCell>
                                    <TableCell
                                    ><b>Servicios</b></TableCell>
                                    <TableCell
                                        align="right"
                                        sx={{
                                            display: { xs: 'none', sm: 'table-cell' }  // Oculta en pantallas pequeñas
                                        }}
                                    ><b>Cancelado</b></TableCell>
                                    <TableCell
                                        align="right"
                                        sx={{
                                            display: { xs: 'none', sm: 'table-cell' }  // Oculta en pantallas pequeñas
                                        }}
                                    ><b>Pagado</b></TableCell>
                                    <TableCell
                                        align="right"
                                        sx={{
                                            display: { xs: 'none', sm: 'table-cell' }  // Oculta en pantallas pequeñas
                                        }}
                                    ><b>Mesero</b></TableCell>
                                    <TableCell
                                        align="right"
                                    ><b>Total (${sumaTotalEliminados})</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reporteEliminados ? reporteEliminados.map((array, index) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row"
                                            align="right"
                                            sx={{
                                                display: { xs: 'none', sm: 'table-cell' }  // Oculta en pantallas pequeñas
                                            }}>{array.hora}</TableCell>
                                        <TableCell
                                        >{array.nombreMesa}</TableCell>
                                        <TableCell
                                            align="right"
                                            sx={{
                                                display: { xs: 'none', sm: 'table-cell' }  // Oculta en pantallas pequeñas
                                            }}
                                        >{array.cancelado}</TableCell>
                                        <TableCell
                                            align="right"
                                            sx={{
                                                display: { xs: 'none', sm: 'table-cell' }  // Oculta en pantallas pequeñas
                                            }}
                                        >
                                            {array.pagado === 'Eliminado' ? <DeleteIcon /> : <ClearIcon />}
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            sx={{
                                                display: { xs: 'none', sm: 'table-cell' }  // Oculta en pantallas pequeñas
                                            }}
                                        >{array.mesero}</TableCell>
                                        <TableCell align="right">${array.total}</TableCell>
                                    </TableRow>
                                )) : null}
                            </TableBody>
                        </Table>
                    </TableContainer >
                </>
                : null}
        </>
    );
};

export default Reportes;