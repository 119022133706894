import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './css/index.css';

const Comanda = () => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const rol = sessionStorage.getItem('rol');
    useEffect(() => {
        cargarPedidosComandas();

        // Actualizar cada minuto para recalcular los colores de fondo
        const interval = setInterval(() => {
            cargarPedidosComandas();
        }, 60000);

        return () => clearInterval(interval);
    }, []);

    const cargarPedidosComandas = async () => {
        try {
            setLoading(true);
            setError(null);

            const toDay = new Date();
            const dia = toDay.getDate() < 10 ? `0${toDay.getDate()}` : `${toDay.getDate()}`;
            const mes = toDay.getMonth() + 1 < 10 ? `0${toDay.getMonth() + 1}` : `${toDay.getMonth() + 1}`;
            const fechaActual = `${toDay.getFullYear()}-${mes}-${dia}`;
            const data = { fecha: fechaActual };
            if (rol === 'Cocina') {
                const response = await axios.post(
                    'https://mariscosdelvalle.com.mx/apis/apisComandas/cargarPedidosComandas.php',
                    data,
                    { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
                );
                console.log(response.data);
                setData(response.data);
            } else if (rol === 'Barra') {
                const response = await axios.post(
                    'https://mariscosdelvalle.com.mx/apis/apisComandas/cargarPedidosComandasBarra.php',
                    data,
                    { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
                );
                console.log(response.data);
                setData(response.data);
            } else if (rol === 'Bebidas') {
                const response = await axios.post(
                    'https://mariscosdelvalle.com.mx/apis/apisComandas/cargarPedidosComandasBebidas.php',
                    data,
                    { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
                );
                console.log(response.data);
                setData(response.data);
            }

        } catch (error) {
            console.error('Error al cargar las comandas:', error);
            setError('Error al cargar las comandas. Por favor, intenta nuevamente.');
        } finally {
            setLoading(false);
        }
    };

    const calcularColorFondo = (horaOrden) => {
        const ahora = new Date();
        const [hora, minutos, segundos] = horaOrden.split(':').map(Number);
        const ordenTime = new Date();
        ordenTime.setHours(hora, minutos, segundos);

        const diferenciaMinutos = Math.floor((ahora - ordenTime) / 60000);

        if (diferenciaMinutos <= 8) {
            return 'bg-green-500'; // Verde
        } else if (diferenciaMinutos <= 14) {
            return 'bg-yellow-500'; // Amarillo
        } else {
            return 'bg-red-500'; // Rojo
        }
    };

    return (
        <div className="p-4 bg-gray-100 min-h-screen">
            <h1 className="text-2xl font-bold text-center mb-4">Comandas</h1>
            {loading ? (
                <div className="flex justify-center items-center min-h-[300px]">
                    <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-blue-500"></div>
                </div>
            ) : error ? (
                <div className="text-red-500 text-center">{error}</div>
            ) : Object.keys(data).length === 0 ? (
                <div className="text-center text-gray-600">No hay comandas disponibles.</div>
            ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {Object.entries(data).map(([ordenId, items]) => (
                        <div
                            key={ordenId}
                            className={`shadow-md rounded-lg p-4 border border-gray-200 ${calcularColorFondo(items[0].hora)}`}
                        >
                            <h2 className="text-lg font-semibold text-white mb-2">
                                Orden: {ordenId}
                            </h2>
                            <p className="text-sm text-white mb-2">
                                Mesero: {items[0].mesero}
                            </p>
                            <p className="text-sm text-white mb-2">
                                Mesa: {items[0].mesa}
                            </p>
                            <p className="text-sm text-white mb-4">
                                Hora: {items[0].hora}
                            </p>
                            <div>
                                {items.map((item) => (
                                    <div
                                        key={item.id}
                                        className="mb-2 p-2 border border-gray-300 rounded bg-white"
                                    >
                                        <h3 className="font-semibold text-gray-700">
                                            {item.producto}
                                        </h3>
                                        <p className="text-sm text-gray-600">
                                            Cantidad: {item.cantidad}
                                        </p>
                                        <p className="text-sm text-gray-600">
                                            Estado: {item.estado}
                                        </p>
                                        <p className="text-sm text-gray-600">
                                            Preparación: {item.preparacion}
                                        </p>
                                        <p className="text-md text-white bg-sky-600 rounded-xl pl-2">
                                            Descripción: {item.descripcion}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Comanda;
